import React from "react"
import Hero from "../../components/hero-studies"
import CaseStudies from "../../components/studies"
import "../../assets/index.css"
import Layout from "../../layouts/layout"

export default () => (
    <Layout title="Case Studies - Ladd Partners">
        <Hero/>
        <CaseStudies/>
    </Layout>
)
