import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const ListingContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
    padding: 100px 0;

    &:last-child {
        margin-bottom: 165px;
    }

    &:nth-child(even) {
        background-color: #F7F8F9;
    }

    > div {
        max-width: 1110px;
        display: flex;
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 1140px) {
        > div {
            padding: 0 20px;
        }
    }
`

const ListingLeft = styled.div`
    width: 350px;
    padding-right: 25px;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0;
    }
`

const ListingRight = styled.div`
    width: calc(100% - 350px);
    padding-left: 50px;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0;
        margin-top: 0px;
    }
`

const ListingImageContainer = styled.div`
    margin-bottom: 32px;
`

const ListingUl = styled.ul`
    font-size: 16px;
    line-height: 24px;
    list-style: none;

    li {
        padding-left: 0px;
        position: relative;

        &:before {
            content: "•";
            position: absolute;
            left: -20px;
            padding-right: 8px;
          }
    }
`;

const query = graphql`
    query {
        allSanityCaseStudy(sort: {fields: [order], order: ASC}) {
            totalCount
            edges {
              node {
                id
                slug
                name
                vertical
                location
                website
                description
                applications
                implementation
                image {
                    asset {
                        fixed(width: 250, height: 60) {
                            ...GatsbySanityImageFixed_noBase64
                        }
                    }
                }
              }
            }
          }
      }
`

function getUrl(website) {
    const fullUrl = "https://" + website;
    return fullUrl;
}

const Listings = ({study}) => (
    <ListingContainer>
        <div>
        <ListingLeft id={study.slug}>
            <ListingImageContainer>
                <Img fixed={study.image.asset.fixed}/>
            </ListingImageContainer>
           <h4 className="margin-bottom-0">{study.name}</h4>
           <p>{study.vertical}</p>
           <a href={getUrl(study.website)} target="_blank">{study.website}</a>
           <p className="margin-top-10">{study.description}</p>
        </ListingLeft>
        <ListingRight>
        <p className="margin-bottom-10">
            <strong>Applications: </strong>
            {study.applications.map((res, index) => <>{ (index ? ', ': '') + res }</>)}
        </p>
        <p className="margin-bottom-10">
            <strong>Solution Implmentation:</strong>
        </p>
        <ListingUl>
            {study.implementation.map(res => <li>{ res }</li>)}
        </ListingUl>
        </ListingRight>
        </div>
    </ListingContainer>
)

function getListings(data) {
    const ListingArray = [];
    data.allSanityCaseStudy.edges.forEach(item => {
        ListingArray.push(<Listings study={item.node} key={item.node.name}></Listings>)
    });

    return ListingArray;
}

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                {getListings(data)}
                </>
            )
        }}
    />
)
